import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Careers = () => (
  <Layout>
    <SEO title="Careers" />
    <div className="contentWrapper">
        <section className="innerSection centerAlign">
            <div className="wrapper">
                <div className="gpwLogo">
                    <a href="//www.greatplacetowork.com/" target="_blank"><img src="//res.cloudinary.com/digital-avenues/image/upload/v1675666905/digitalavenues.com/cirtificate-badge.png" alt="Great Place To Work" /></a>
                </div>
                <h2 className="mainHeading">We are Growing!</h2>
                <p className="subHead">
                    <span>At Digital Avenues, we are driven by passion. We believe good work inspires us all and we’re committed to cultivating an environment that helps you grow. Joining our team means you’ll also receive guidance and mentorship from our director and project leaders.</span>
                    <span>Come, join our team and let’s work together to build something awesome!</span>
                </p>
                <h3>Interested?</h3>
                <p>Send us your resume at <a href="mailto:careers@digitalavenues.com" target="_blank">careers@digitalavenues.com</a></p>
            </div>
        </section>
        <section className="innerSection semiDarkContainer">
            <div className="wrapper">
                <h2>Challenge, Solve, Challenge</h2>
                <p className="subHead">
                    <span>Company wide, we've created an environment where innovative thinking is encouraged, initiative is rewarded, and reputations are made. We are about quality and commitment to delivery. Each team member is a self-starter with the ability to learn and excel at what he or she takes ownership of.</span>
                    <span>At Digital Avenues authority is determined not by your designation – it is a function of your domain skills, your personal traits and team spirit.</span>
                </p>
            </div>
        </section>
        <section className="innerSection">
            <div className="wrapper">
                <h2>A Great Fit</h2>
                <p className="subHead">
                    <span>At Digital Avenues we believe in diversity and equal opportunity of employment. We strive to inculcate in all who join our family, a sense of pride, a spirit of fairness and an environment, where the diversity of talent amongst our team members is recognized as our strongest asset.</span>
                </p>
                <div className="mapWrap noContact">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616559315/digitalavenues.com/static/map.png" alt="" />
                </div>
            </div>
        </section>
    </div>
  </Layout>
)

export default Careers
